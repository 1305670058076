import { SearchCriteria } from './search-criteria.model';

export class ProductSearchDetails {
    selectedLicensorsForSearch: string[] = [];
    selectedBrandsForSearch: string[] = [];
    selectedColorsForSearch: string[] = [];
    selectedGarmentsForSearch: string[] = [];
    selectedTreatmentsForSearch: string[] = [];
    selectedCategoriesForSearch: string[] = [];
    selectedBrandCategoriesForSearch: string[] = [];
    selectedPrintEffectsForSearch: string[] = [];
    selectedTagsForSearch: string[] = [];
    selectedCreatedByListForSearch: string[] = [];
    selectedApprovalStatusListForSearch: string[] = [];
    selectedArtStatusListForSearch: string[] = [];
    selectedExclusiveListForSearch: string[] = [];
    searchString: string = "";
    productIdListString: string = "";
    selectedSortByName:string='';
    sortMode='';
    filterVisible:boolean=false;
    lastSelection: number;
    type:string="";
    excludeInactiveBrandAndLicensor:boolean;
    loadMoreCount: number=25;
    totalCount: number;
    loadMore: boolean=false;
    loadedCount: number;
    previousSearchQuery: string;
    dashBoardSelectedIndex:number=-1;
    excludeMock:boolean=false;
    excludeSku:boolean=false;
    excludeProductType:string='ALL';
    excludeAdhocProduct:boolean=true;
    searchInSalesOrder:boolean=false;

   constructor(type:string,excludeInactiveBrandAndLicensor:boolean,selectedSortByName:string,sortMode:string){
      this.type=type;
      this.excludeInactiveBrandAndLicensor=excludeInactiveBrandAndLicensor;
      this.selectedSortByName=selectedSortByName;
      this.sortMode=sortMode
      this.resetLoadMoreCount();
   }

   resetLoadMoreCount() {
      this.loadMoreCount = 25;
   }

    /**GENERIC STARTS*/
   getListForType(type: string): string[] {
    if (type === 'LICENSORS') {
       return this.selectedLicensorsForSearch;
    } else if (type === 'BRANDS') {
       return this.selectedBrandsForSearch;
    } else if (type === 'CATEGORY') {
       return this.selectedCategoriesForSearch;
    } else if (type === 'BRAND_CATEGORY') {
       return this.selectedBrandCategoriesForSearch;
    } else if (type === 'PRINT_EFFECT') {
       return this.selectedPrintEffectsForSearch;
    } else if (type === 'TAGS') {
       return this.selectedTagsForSearch;
    } else if (type === 'APPROVAL_STATUS') {
       return this.selectedApprovalStatusListForSearch;
    } else if (type === 'ART_STATUS') {
      return this.selectedArtStatusListForSearch;
    }else if (type === 'TREATMENT') {
       return this.selectedTreatmentsForSearch
    } else if (type === 'GARMENT') {
       return this.selectedGarmentsForSearch;
    } else if (type === 'COLOR') {
       return this.selectedColorsForSearch;
    } else if (type === 'EXCLUSIVE') {
      return this.selectedExclusiveListForSearch;
   }
 }
 setListForType(type: string, list: string[]) {
    if (type === 'LICENSORS') {
       this.selectedLicensorsForSearch = list;
    } else if (type === 'BRANDS') {
       this.selectedBrandsForSearch = list;
    } if (type === 'CATEGORY') {
       this.selectedCategoriesForSearch = list;
    } else if (type === 'BRAND_CATEGORY') {
       this.selectedBrandCategoriesForSearch = list;
    } else if (type === 'PRINT_EFFECT') {
       this.selectedPrintEffectsForSearch = list;
    } else if (type === 'TAGS') {
       this.selectedTagsForSearch = list;
    } else if (type === 'APPROVAL_STATUS') {
       this.selectedApprovalStatusListForSearch = list;
    } else if (type === 'ART_STATUS') {
      this.selectedArtStatusListForSearch = list;
      }else if (type === 'TREATMENT') {
       this.selectedTreatmentsForSearch = list;
    } else if (type === 'GARMENT') {
       this.selectedGarmentsForSearch = list;
    } else if (type === 'COLOR') {
       this.selectedColorsForSearch = list;
    } else if (type === 'EXCLUSIVE') {
       this.selectedExclusiveListForSearch =list;
   }
 }

 add(item: string, type: string) {
    if (!this.isSelected(item, type)) {
       let list = this.getListForType(type);
       list.push(item);
       this.setListForType(type, list);
    }
 }

 remove(item: string, type: string) {
    //
    this.setListForType(type, this.getListForType(type).filter(itemInList => itemInList !== item));
 }

 isSelected(item: string, type: string): boolean {
    let temp: string[] = this.getListForType(type).filter(itemInList => itemInList === item);
    return (temp.length == 1);
 }

 resetSelectedForSearch(type: string) {
    this.setListForType(type, []);
 }
 /***************GENERIC ENDS*****************/

 /**searchString STARTS*/
 /*setSearchString(item: string) {
    this.searchString = item;
 }
 resetSearchString(searchstring: string) {
    this.searchString = "";
 }*/
 getSearchString(): string {
    return this.searchString;
 }
 getProductIdListString():string{
    return this.productIdListString;
 }
 /**searchString ENDS*/

 getListForTypeForSearch(type:string):string[]{
    let retVal=null;
    let retValTemp=this.getListForType(type);
    if(retValTemp!==undefined && retValTemp.length>0){
       retVal=retValTemp;
    }
    return retVal;
 }

 getSearchCriteria(): SearchCriteria {
    const searchCriteria = new SearchCriteria;
       searchCriteria.licensors = this.getListForTypeForSearch('LICENSORS');
       searchCriteria.brands = this.getListForTypeForSearch('BRANDS');
       searchCriteria.colors = this.getListForTypeForSearch('COLOR');
       searchCriteria.garments = this.getListForTypeForSearch('GARMENT');
       searchCriteria.treatments = this.getListForTypeForSearch('TREATMENT');
       searchCriteria.tags = this.getListForTypeForSearch('TAGS');
       searchCriteria.approvalStatus = this.getListForTypeForSearch('APPROVAL_STATUS');
       searchCriteria.artStatus = this.getListForTypeForSearch('ART_STATUS');
       searchCriteria.categories = this.getListForTypeForSearch('CATEGORY');
       searchCriteria.printEffects = this.getListForTypeForSearch('PRINT_EFFECT');
       searchCriteria.brandCategories = this.getListForTypeForSearch('BRAND_CATEGORY');
       searchCriteria.exclusives = this.getListForTypeForSearch('EXCLUSIVE');
       searchCriteria.searchString = this.getSearchString();
       searchCriteria.productIdListString = this.getProductIdListString();
       searchCriteria.excludeInactiveBrandAndLicensor=this.excludeInactiveBrandAndLicensor;
       searchCriteria.searchType=this.type;
       searchCriteria.sortMode=this.sortMode;
       searchCriteria.selectedSortByName=this.selectedSortByName;
       //loadMore
       searchCriteria.loadMore=this.loadMore;
       //
       if(!this.loadMore && this.lastSelection>0 && this.lastSelection>this.loadMoreCount){
         this.loadMoreCount=this.loadMoreCount+this.lastSelection;
         //
       }
       searchCriteria.loadMoreCount=this.loadMoreCount;
       searchCriteria.loadedCount=this.loadedCount;
       searchCriteria.totalCount=this.totalCount;
       searchCriteria.previousSearchQuery=this.previousSearchQuery;
       searchCriteria.excludeAdhocProduct=this.excludeAdhocProduct;
       //loadMore
       switch (this.excludeProductType) {
          case 'ALL':
            this.excludeMock=false;
            this.excludeSku=false; 
            this.searchInSalesOrder=false;
             break;
         case 'MOCK':
            this.excludeMock=true;
            this.excludeSku=false;
            this.searchInSalesOrder=false;
         break;
         case 'SKU':
            this.excludeMock=false;
            this.excludeSku=true;
            this.searchInSalesOrder=false;
         break;
         case 'SALES':
            this.searchInSalesOrder=true;
            this.excludeMock=false;
            this.excludeSku=false; 
            break;
          default:
             break;
       }
       
       if(this.type==='SKU_TRACKER'){
         this.searchInSalesOrder=false;
          searchCriteria.excludeConfiguredSKU=true;
          searchCriteria.excludeMock=true;
          searchCriteria.excludeSku=false;
       }else{
         searchCriteria.excludeMock=this.excludeMock;
         searchCriteria.excludeSku=this.excludeSku;
         searchCriteria.searchInSalesOrder=this.searchInSalesOrder;
       }
       //
    return searchCriteria;
}

 setResultMetadata(searchresult:SearchCriteria){
    //('SearchResult ->',searchresult)
    this.totalCount=searchresult.totalCount;
    this.loadMore=searchresult.loadMore;
    this.loadMoreCount=searchresult.loadMoreCount;
    this.loadedCount=searchresult.loadedCount;
    this.previousSearchQuery=searchresult.previousSearchQuery;
}

 resetAdvSearchCriteria() {
    this.resetSelectedForSearch('LICENSORS');
    this.resetSelectedForSearch('BRANDS');
    this.resetSelectedForSearch('CATEGORY');
    this.resetSelectedForSearch('BRAND_CATEGORY');
    this.resetSelectedForSearch('PRINT_EFFECT');
    this.resetSelectedForSearch('TAGS');
    this.resetSelectedForSearch('APPROVAL_STATUS');
    this.resetSelectedForSearch('ART_STATUS');
    this.resetSelectedForSearch('TREATMENT');
    this.resetSelectedForSearch('GARMENT');
    this.resetSelectedForSearch('COLOR');
    this.resetSelectedForSearch('EXCLUSIVE');
    this.excludeMock=false;
    this.excludeSku=false;
    this.excludeProductType='ALL';
 }
}
