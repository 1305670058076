import { Injectable } from '@angular/core';
import { ExclusiveCustomer, Product, ProductAttachment, SubmissionSheetDetails } from '../model/product.model';
import { SearchCriteria } from '../model/search-criteria.model';
import { HttpClient, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { FileDeleteCriteria } from '../model/file-delete-criteria.model';
import { LoaderService } from './loader.service';
import { ServiceStatus } from '../model/service-status.model';
import { ApiResponse } from '../model/api-response';
import { PrinterMatrix } from '../model/printer-matrix.model';
import { SharedService } from './shared.service';
import { ToastrService } from 'ngx-toastr';
import { OrderDetail } from '../model/sales/order-detail.model';
import { SalesOrder } from '../model/sales/sales-order.model';
import { ComposeEmail } from '../model/compose-email.model';
import { EmailLog } from '../model/email-log.model';
import { ComposeEmailService } from './compose-email.service';
import { BrandPnlSpeciality } from '../model/brand.model';

@Injectable({
   providedIn: 'root'
})
export class ProductService {
  
   readonly rooturl = environment.apiEndPoint;
   constructor(private http: HttpClient,private loaderService:LoaderService, private sharedService:SharedService, 
      private toastrService:ToastrService,private composeEmailService:ComposeEmailService) {
   }

   loadUrlsOfFileCatgeoryForProductId(category: string, licensorId: string, brandId: string, productId: string) {
      return this.http.get(this.rooturl + "/product/loadProductImage/" + category + "/" + licensorId + "/" + brandId + "/" + productId);
   }
   
   /*
   loadAttachmentOfCategory(category: string, licensorId: string, brandId: string, productId: string) {
      return this.http.get(this.rooturl + "/fileHandler/loadAttachment/" + category + "/" + licensorId + "/" + brandId + "/" + productId);
   }
   */

   loadProductForSearch(searchCriteria: SearchCriteria): Observable<SearchCriteria> {
      return this.http.post<SearchCriteria>(this.rooturl + "/product/search/", searchCriteria);
   }
   loadSimilarProduct(searchCriteria: SearchCriteria): Observable<Product[]> {
      return this.http.post<Product[]>(this.rooturl + "/product/similarProduct/", searchCriteria);
   }

   loadProductForSearchWithoutLoader(searchCriteria: SearchCriteria): Observable<Product[]> {
      return this.http.post<Product[]>(this.rooturl + "/productNonBlocking/search/", searchCriteria);
   }

   createProduct(product: Product) {
      return this.http.post(this.rooturl + "/product/create", product);
   }

   updateProduct(product: Product) {
      return this.http.post(this.rooturl + "/product/update", product);
   }

   deleteFile(criteria: FileDeleteCriteria) {
      return this.http.post(this.rooturl + "/product/file/delete", criteria);
   }

   deleteProduct(productId: string) {
      return this.http.get(this.rooturl + "/product/delete/" + productId);
   }

   uploadFileForProductWithProgress(product: Product, file: File, category: string) {
      let id=Math.random().toString(36).substr(2, 9);
      //
      const fd = new FormData();
      fd.append('file', file, file.name);
      //fd.append('product', JSON.stringify(product));
      
      return this.http.post(this.rooturl + "/product/uploadFile/" + category + "/" + product.licensorId + "/" + product.brandId + "/" + product.id, fd, {
         reportProgress: true,
         observe: 'events'
      }).pipe(map((event) => {
         
         //
         switch (event.type) {
            case HttpEventType.Sent:
               this.loaderService.showProgressBar(product.sku,file.name,id);
               break;
            case HttpEventType.UploadProgress:
               const progress = Math.round(100 * event.loaded / event.total);
               //
               this.loaderService.updateProgress(product.sku,file.name,id,progress);
               break;
            case HttpEventType.Response:
               let serviceStatus=event.body as ServiceStatus;
               if(serviceStatus && serviceStatus.status==='SUCCESS'){
                  this.loaderService.hideProgressBar(product.sku,file.name,id);
                  return 'UPLOAD_SUCCESS';
               }else{
                  this.loaderService.hideProgressBar(product.sku,file.name,id);
                  return 'UPLOAD_FAILED';
               }
            default:
               //
            // return `Unhandled event: ${event.type}`;
            break;
         }
      })
      ).pipe(
         catchError(this.errorMgmt)
      );
   }
/*
   uploadFileWithProgress(object: any,varName:string, file: File, category: string,searchCriteria:SearchCriteria) {
      let id=Math.random().toString(36).substr(2, 9);
      //
      const fd = new FormData();
      fd.append('file', file, file.name);
      //fd.append('product', JSON.stringify(product));
      
      return this.http.post(this.rooturl + "/fileHandler/uploadFile/" + category + "/" + searchCriteria.licensorId + "/" + searchCriteria.brandId + "/" + searchCriteria.productId, fd, {
         reportProgress: true,
         observe: 'events'
      }).pipe(map((event) => {
         
         switch (event.type) {
            case HttpEventType.Sent:
               this.loaderService.showProgressBar(object[varName],file.name,id);
               break;
            case HttpEventType.UploadProgress:
               const progress = Math.round(100 * event.loaded / event.total);
               //
               this.loaderService.updateProgress(object[varName],file.name,id,progress);
               break;
            case HttpEventType.Response:
               //
              //
               let serviceStatus=event.body as ServiceStatus;
               if(serviceStatus && serviceStatus.status==='SUCCESS'){
                  this.loaderService.hideProgressBar(object[varName],file.name,id);
                  return 'UPLOAD_SUCCESS';
               }else{
                  this.loaderService.hideProgressBar(object[varName],file.name,id);
                  return 'UPLOAD_FAILED';
               }
            default:
             //  
            // return `Unhandled event: ${event.type}`;
            break;
         }
      })
      ).pipe(
         catchError(this.errorMgmt)
      );
   }*/

   errorMgmt(error: HttpErrorResponse) {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
      } else {
         // Get server-side error
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      console.error('Error in file upload:',errorMessage);
      return throwError(errorMessage);
   }

   isCurrentVersion(productId:string, versionToCheck:number){
      return this.http.get(this.rooturl + "/product/isCurrentVersion/" + productId+"/"+versionToCheck);
   }

   loadProduct(searchCriteria: SearchCriteria) {
      return this.http.get(this.rooturl + "/product/load/" + searchCriteria.productId);
   }
   loadProductById(productId: string) {
      return this.http.get(this.rooturl + "/product/load/" + productId);
   }

   cloneProduct(searchCriteria: SearchCriteria) {
      return this.http.get(this.rooturl + "/product/clone/" + searchCriteria.productId);
   }
   cloneProductWithFiles(searchCriteria: SearchCriteria) {
      return this.http.get(this.rooturl + "/product/cloneProductWithFiles/" + searchCriteria.productId);
   }
   loadCollection(searchCriteria){
      return this.http.get(this.rooturl + "/product/loadCollection/" + searchCriteria.productId);
   }
   loadCollectionMembers(searchCriteria){
      return this.http.get(this.rooturl + "/product/loadCollectionMembers/" + searchCriteria.productId);
   }
   download(url: string) {
      return this.http.get(url, { responseType: 'arraybuffer' });
   }

   loadTags() {
      return this.http.get(this.rooturl + "/product/loadTags");
   }

   createCollectionProduct(searchCriteria: SearchCriteria) {
      return this.http.post(this.rooturl + "/product/createCollection", searchCriteria);
   }

   resetCollectionAttachments(searchCriteria: SearchCriteria) {
      return this.http.post(this.rooturl + "/product/resetCollectionAttachments", searchCriteria);
   }

   /********SUBMISSIONSHEET CHANGES */

   createSubmissionSheet(submissionSheetDetails: SubmissionSheetDetails) {
      return this.http.post(this.rooturl + "/product/createSubmissionSheet", submissionSheetDetails);
   }
   confirmSubmissionSheet(submissionSheetDetails: SubmissionSheetDetails) {
      return this.http.post(this.rooturl + "/product/confirmSubmissionSheet", submissionSheetDetails);
    }
    approvalStatusChanged(productId: string, previousStatus:string,propogateStatusToMembers:boolean) {
       if(propogateStatusToMembers===undefined){
          propogateStatusToMembers=true;
       }
      return this.http.get(this.rooturl + "/product/approvalStatusChanged/"+productId+"/"+previousStatus+"/"+propogateStatusToMembers);
    }

    getSubmissionSheet(licensorId: string, brandId: string, productId: string, type: string, subType: string) {
      return this.http.get(this.rooturl + "/product/submissionSheet/"+licensorId+"/"+brandId+"/"+productId+"/"+type+"/"+subType);
    }

    getStatusDetails(productId: string) {
      return this.http.get(this.rooturl + "/product/getStatusDetails/"+productId);
   }

   getSampleDetails(productId: string) {
      return this.http.get(this.rooturl + "/product/getSampleDetails/"+productId);
   }

   getSkuTrackerRetailerInfo(productId: string) {
      return this.http.get(this.rooturl + "/product/getSkuTrackerRetailerInfo/"+productId);
   }

   /*getNextAvaiableSKUPrefix(brandId:string) {
      return this.http.get(this.rooturl + "/product/getNextAvaiableSKUPrefix/"+brandId);
   }*/

   getNextAvaiableSKUSeries(product:Product) {
      return this.http.post(this.rooturl + "/product/getNextAvaiableSKUSeries",product);
   }


   /**** DashBoard Sarts***/
   
   getSkuDashBoardDetails() {
      return this.http.get(this.rooturl + "/dashboard/sku/getSkuDashBoardDetails");
    }

    /*****Dashboard Ends***/ 
    getExclusiveCustomersList(){
      return this.http.get<string[]>(this.rooturl + '/product/exclusive/customer/list');
   }
  
   getExclusiveCustomers(productId: string){
      return this.http.post<ExclusiveCustomer[]>(this.rooturl + '/product/list/exclusive/customer', productId);
   }
   updateExclusiveCustomer(productId:string,exclusiveCustomers: ExclusiveCustomer[]) {
      if(!exclusiveCustomers || exclusiveCustomers===null){
         exclusiveCustomers=[];
       }
      return this.http.post(this.rooturl + "/product/updateExclusiveCustomer/"+productId, exclusiveCustomers);
    }

   getPrinterMatrix(productId: string){
      return this.http.post<any>(this.rooturl + '/product/load/printerMatrix', productId);
   }

   updatePrinterMatrix(productId:string,printerMatrix: PrinterMatrix) {
      if(!printerMatrix || printerMatrix===null){
         printerMatrix=new PrinterMatrix;
       }
      return this.http.post(this.rooturl + "/product/update/printerMatrix/"+productId, printerMatrix);
    }

    handleApprovalEmailSent(productId:string,type:string,subType:string) {
      return this.http.get(this.rooturl + "/product/update/handleApprovalEmailSent/"+type+"/"+subType+"/"+productId);
    }

    
   renameProductAttachment(attachment:ProductAttachment){
      return this.http.post(this.rooturl + "/product/file/rename/",attachment);
   }
   changePriority(id: string, priority: boolean) {
         return this.http.post(this.rooturl + "/product/update/priority/"+priority,id);
   } 
   isProductExclusiveToCustomer(productId: string,customerId: string) {
      return this.http.get(this.rooturl + "/product/isCustomerExclusive/"+productId+'/'+customerId);
   }





   loadProductAttachmentsUrls(product:Product,category: string,licensorId:string,brandId:string) {
      this.loadUrlsOfFileCatgeoryForProductId(category, licensorId, brandId, product.id).subscribe((res) => {
        this.sharedService.loadMimeTypes(res as ProductAttachment[]);
        switch (category) {
          case 'SEPERATIONS':product.seperations = res as ProductAttachment[];break;
          case 'PNL':product.brandPnl = res as ProductAttachment[];break;
          case 'MASTER_ARTS':product.masterArts = res as ProductAttachment[];break;
          case 'APPROVAL_DOCS':product.approvalDocs = res as ProductAttachment[];break;
          case 'PHOTO_APPROVAL':product.photoApproval = res as ProductAttachment[];break;
          case 'PNG_FRONT':product.pngFront = res as ProductAttachment[];break;
          case 'PNG_BACK':product.pngBack = res as ProductAttachment[];break;
          default:break;
        }
      },
        (err) => {
          console.error(err);
          //this.LoadFileErrorFlag = true;
          //this.LoadFileError = err.message;
        });
  
    }

    loadProductImagesUrls(product:Product,licensorId:string,brandId:string) {
      this.loadUrlsOfFileCatgeoryForProductId('IMAGES', licensorId, brandId, product.id).subscribe((res) => {
         product.productImages = res as ProductAttachment[];
         this.sharedService.loadMimeTypes(product.productImages);
    },
      (err) => {
        this.toastrService.error('Error wile loading product visuals. '+err);
        //this.LoadFileErrorFlag = true;
        //this.LoadFileError = err.message;
      });
  }

  getPreferredPnlForOrder(salesOrder:SalesOrder,orderDetail:OrderDetail,brandPnlSpecialities:BrandPnlSpeciality[]):ProductAttachment[]{
   if(orderDetail && orderDetail.product){
      if(orderDetail.pnlSpecialityRequired && orderDetail.pnlSpecialityType!=='Buyer Provided PNL' && orderDetail.product.brandPnl && orderDetail.product.brandPnl.length>0){
         console.log(orderDetail.pnlSpecialityType,orderDetail.product.brandPnl);
         if(orderDetail.pnlSpecialityType==='Request Custom PNL'){
            return this.getPreferredPnl(orderDetail.product);
         }else{
            let pnlSpecialtyAttachment=undefined;
            let selectBrandSpeciality=brandPnlSpecialities.filter(x=>x.displayName===orderDetail.pnlSpecialityType);
            if(selectBrandSpeciality && selectBrandSpeciality.length>0){
               pnlSpecialtyAttachment=orderDetail.product.brandPnl.filter(x=>x.originalFileName===selectBrandSpeciality[0].fileName);
            }
            return pnlSpecialtyAttachment;
         }
         
      }else if(orderDetail.pnlSpecialityRequired && orderDetail.pnlSpecialityType==='Buyer Provided PNL'){
         if(salesOrder.pnlSpecialityAttachments && salesOrder.pnlSpecialityAttachments.length>0){
            return salesOrder.pnlSpecialityAttachments.filter(attachment=> attachment.groupReferenceId===orderDetail.productId);
         }
         return [];
      }
      else{
         return this.getPreferredPnl(orderDetail.product);
      }
   }
   
}

   getPreferredPnl(product:Product):ProductAttachment[]{
      if(product.brandPnl && product.brandPnl.length>0){
      let likedAttachment=product.brandPnl.filter(x=>x.liked);
      if(likedAttachment && likedAttachment.length>0){
         return likedAttachment;
      }else{
         return product.brandPnl;
      }
      }else{
      return [];
      }
   }
   logApprovalEmail(composeEmail: ComposeEmail, product: Product,subType:string) {
      let emailLog:EmailLog=new EmailLog;
      emailLog.parentId=product.id;
      emailLog.sentDate=new Date;
      emailLog.type="APPROVAL_EMAIL";
      emailLog.subType=subType;
      emailLog.category="PRODUCT";
      emailLog.emailSubject=composeEmail.emailSubject;
      emailLog.emailTo=this.sharedService.getConcatenatedString(composeEmail.emailTo, 250);
      emailLog.emailCc=this.sharedService.getConcatenatedString(composeEmail.emailCc, 250);
      emailLog.emailBcc=this.sharedService.getConcatenatedString(composeEmail.emailBcc, 250);
      emailLog.emailAttachments=this.sharedService.getConcatenatedString(
                                this.sharedService.getListOfAttachmentNames(composeEmail.emailAttachments),250);
      this.composeEmailService.logApprovalEmail(emailLog).subscribe(response => {
        let serviceStatus:ServiceStatus=response as ServiceStatus;
        if (serviceStatus.status === 'SUCCESS') {
        } else {
           console.error("Email logging failed : ", serviceStatus.message);
           this.toastrService.error(serviceStatus.message);
        }
     },
        error => {
           console.error(error);
           this.toastrService.error(error.message);
        });
   }
   async handleApprovalEmailSentForProduct(product:Product,type:string,subType:string) {
      await this.handleApprovalEmailSent(product.id,type,subType).toPromise().then( res =>{
        let serviceStatus:ServiceStatus=res as ServiceStatus;
        if(serviceStatus.status==='SUCCESS'){
         this.toastrService.warning('Approval Status Changed!');
        } else if(serviceStatus.status==='NOT_APPLICABLE'){
         //this.toastrService.info('Approval Status Changed Not Applicable!');
        }else{
          this.toastrService.error('Approval Status Change failed - '+serviceStatus.message);
        }
      }
   
      );
     }
}
